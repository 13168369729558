import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpResponse, HttpClient, HttpHeaders } from '@angular/common/http';
import { appSettings } from '../app.setting';
import { SysSystemSetupModel } from '../models/sys-system-setup/SysSystemSetupModel';
import { SysSystemSetupForm } from '../models/sys-system-setup/SysSystemSetupForm';

@Injectable({
  providedIn: 'root'
})

export class SysSystemSetupService {
  endPointUrl: string;
  headers: HttpHeaders;
  constructor(private http: HttpClient, @Inject('BASE_URL') private url: string) {
    this.endPointUrl = this.url + appSettings.endPoint + 'SysSystemSetup';
    if (localStorage.getItem('currentUser')) {
      this.headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('currentUser')).loginToken
      });
    }
  }

  getModel(id: number): Observable<HttpResponse<SysSystemSetupModel>> {
    return this.http.get<SysSystemSetupModel>(this.endPointUrl + '/' + id,
      {
        headers: this.headers,
        observe: 'response'
      }
    );
  }

  update(sysSystemSetupForm: SysSystemSetupForm): Observable<HttpResponse<SysSystemSetupModel>> {
    return this.http.put<SysSystemSetupModel>(this.endPointUrl, sysSystemSetupForm,
      {
        headers: this.headers,
        observe: 'response'
      }
    );
  }
}
