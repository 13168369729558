import { HttpHeaders } from '@angular/common/http';
import { Inject } from '@angular/core';

interface AppSettings {
  endPoint?: string;
  appVersion?: string;
  httpHeaders?: HttpHeaders;
}

export const appSettings: AppSettings = {
  endPoint: "api/",
  appVersion: '0.0.1',//"[Your APP Version]"
  httpHeaders: new HttpHeaders({
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET, POST, DELETE, PUT',
    'Access-Control-Allow-Headers': 'X-Requested-With,content-type',
    'Access-Control-Allow-Credentials': 'true'
  })
};
