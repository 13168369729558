import { Component } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styles: ['./home.component.css']
})
export class HomeComponent {

  constructor() {
  }

  scroll(el: HTMLElement) {
    el.scrollIntoView();
  }

}
