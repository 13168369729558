import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { FrontMenuManagementModel } from '../../models/front-menu-management/FrontMenuManagementModel';
import { StaMenuManagementModel } from '../../models/sta-menu-management/StaMenuManagementModel';
import { SysSystemSetupModel } from '../../models/sys-system-setup/SysSystemSetupModel';
import { FrontMenuManagementService } from '../../services/FrontMenuManagementService';
import { StaMenuManagementService } from '../../services/StaMenuManagementService';
import { SysSystemSetupService } from '../../services/SysSystemSetupService';
import { CarouselComponent, OwlOptions } from 'ngx-owl-carousel-o';
declare var tableau: any;
@Component({
    selector: 'app-view-detail',
    templateUrl: './view-detail.component.html',
    styleUrls: ['./view-detail.component.css']
})
/** view-detail component*/
export class ViewDetailComponent implements OnInit {
  mainId: number;
  subId: number;
  id: number;
  subscription: any;
  currentUrl: string;
  selectedNav = 1;
  errMsg: string;
  urlDetail = "/view-detail/";
  mainStaMenuManagementModel: StaMenuManagementModel;
  subStaMenuManagementModel: StaMenuManagementModel;
  FrontMenuManagementModel: FrontMenuManagementModel;
  FrontMenuManagementModels: FrontMenuManagementModel[];
  sysSystemSetupModel: SysSystemSetupModel;
  viz: any;
  vizChecker: any;
  btnChart:boolean;
  btnSummary:boolean;
  customOptions: OwlOptions;
  @ViewChild('owlCar') owlCar: CarouselComponent;
  constructor(
    private frontMenuManagementService: FrontMenuManagementService,
    private staMenuManagementService: StaMenuManagementService,
    private sysSystemSetupService: SysSystemSetupService,
    private route: ActivatedRoute,
    private router: Router
  )
  {
    this.customOptions = {
      loop: true,
      mouseDrag: true,
      touchDrag: true,
      pullDrag: false,
      dots: true,
      navSpeed: 500,
      responsive: {
        0: {
          items: 1
        },
        600: {
          items: 2
        },
        1000: {
          items: 3
        }
      },
      nav: false,
      navText: [
        '<i class="fa fa-angle-left" aria-hidden="true"></i>',
        '<i class="fa fa-angle-right" aria-hidden="true"></i>'
      ],
    }
    this.subscription = router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        if (val.url != this.currentUrl && val.url.indexOf('view') != -1) {
          this.currentUrl = val.url;
          this.ngOnInit();
        }
      }
    });
  }

  viewDetail(id: number) {
    window.scroll(0,0);
    this.router.navigateByUrl(this.urlDetail
      + this.mainId
      + '/' + this.subId
      + '/' + id);
  }

  owlPrev() {
    this.owlCar.prev();
  }

  owlNext() {
    this.owlCar.next();
  }

  ngOnInit(): void {
    this.mainId = parseInt(this.route.snapshot.paramMap.get('mainId'));
    this.subId = parseInt(this.route.snapshot.paramMap.get('subId'));
    this.id = parseInt(this.route.snapshot.paramMap.get('id'));
    this.staMenuManagementService.getGetId(this.mainId).subscribe(res => {
      if (res.ok) {
        this.mainStaMenuManagementModel = res.body;
      }
    });
    this.staMenuManagementService.getGetId(this.subId).subscribe(res => {
      if (res.ok) {
        this.subStaMenuManagementModel = res.body;
      }
    });
    this.frontMenuManagementService.getFrontMenuManagementModel(this.id).subscribe(res => {
      this.clearTableau();
      if (res.ok) {
        this.FrontMenuManagementModel = res.body;
        if (this.FrontMenuManagementModel) {
          //check hide button
          this.btnChart = this.checkTablue(1);
          this.btnSummary = this.checkTablue(2);
          if(this.btnChart){
            this.selectedNav = 1;
          }else{
            this.selectedNav = 2;
          }
          this.getTablue(this.selectedNav);
        }
      }
    },
      err => { });
    this.frontMenuManagementService.getFrontMenuManagementForFrontEndCK(this.subId).subscribe(res => {
      if (res.ok) {
        this.FrontMenuManagementModels = res.body;
      }
    },
      err => { });
    this.sysSystemSetupService.getModel(1).subscribe(res => {
        if (res.ok) {
          this.sysSystemSetupModel = res.body;
        }
      },
        err => { });
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
  get MainTitle() {
    if (this.mainStaMenuManagementModel) {
      return this.mainStaMenuManagementModel.title;
    } else return '';
  }
  get SubTitle() {
    if (this.subStaMenuManagementModel) {
      return '('+this.subStaMenuManagementModel.title+')';
    } else return '';
  }
  get Title() {
    if (this.FrontMenuManagementModel) {
      return this.FrontMenuManagementModel.title;
    } else return '';
  }
  get DetailOfMenu(): string {
    if (!this.selectedNav || this.selectedNav == 0)
      return '';
    else {
      let getModel = this.FrontMenuManagementModel;
      if (getModel) {
        return getModel.description;
      }
    }
  }
  get FrontMenuManagementModelsRelate() {
    if (this.FrontMenuManagementModels) {
      return this.FrontMenuManagementModels.filter(x => x.pkFoMma != this.id);
    } else return [];
  }

  checkTablue(id:number){
    if (this.vizChecker) this.vizChecker.dispose();
    try {
      var placeholderDiv = document.getElementById('vizDisplayChecker');
      let getModel = this.FrontMenuManagementModel;
      if (getModel) {
        if (id == 1 && getModel.tableauUrl1 && getModel.tableauUrl1 != '') {
          var obj = this.setConfigTableau(getModel.tableauUrl1);
          this.vizChecker = new tableau.Viz(placeholderDiv, obj.url, obj.options);
        } else if (id == 2 && getModel.tableauUrl2 && getModel.tableauUrl2 != '') {
          var obj = this.setConfigTableau(getModel.tableauUrl2);
          this.vizChecker = new tableau.Viz(placeholderDiv, obj.url, obj.options);
        }
      }
    } catch {
      //this.errMsg = "No Data";
      return false;
    }
    return true;
  }

  getTablue(id: number) {
    this.selectedNav = id;
    this.errMsg = "";
    try {
      this.clearTableau();
      var placeholderDiv = document.getElementById('vizDisplay');
      let getModel = this.FrontMenuManagementModel;
      if (getModel) {
        if (this.selectedNav == 1 && getModel.tableauUrl1 && getModel.tableauUrl1 != '') {
          var obj = this.setConfigTableau(getModel.tableauUrl1);
          this.viz = new tableau.Viz(placeholderDiv, obj.url, obj.options);
        } else if (this.selectedNav == 2 && getModel.tableauUrl2 && getModel.tableauUrl2 != '') {
          var obj = this.setConfigTableau(getModel.tableauUrl2);
          this.viz = new tableau.Viz(placeholderDiv, obj.url, obj.options);
        }
      }
    } catch {
      this.errMsg = "No Data";
    }
  }

  clearTableau() {
    this.errMsg = "";
    if (this.viz) this.viz.dispose();
  }


  setConfigTableau(_url: string): any {
    var url = _url;
    var options = {
      hideTabs: true,
      width: "100%",
      height: "650px",
      onFirstInteractive: function () {
        // The viz is now ready and can be safely used.
        console.log("Run this code when the viz has finished     loading.");
      }
    };
    return {
      url: url,
      options: options
    }
  }
}
