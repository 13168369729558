import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpResponse, HttpClient, HttpHeaders } from '@angular/common/http';
import { appSettings } from '../app.setting';
import { StaMenuManagementModel } from '../models/sta-menu-management/StaMenuManagementModel';
import { StaMenuManagementForm } from '../models/sta-menu-management/StaMenuManagementForm';

@Injectable({
  providedIn: 'root'
})

export class StaMenuManagementService {
  endPointUrl: string;
  headers: HttpHeaders;
  constructor(private http: HttpClient, @Inject('BASE_URL') private url: string) {
    this.endPointUrl = this.url + appSettings.endPoint + 'StaMenuManagement';
    if (localStorage.getItem('currentUser')) {
      this.headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('currentUser')).loginToken
      });
    }
  }

  getAll(): Observable<HttpResponse<StaMenuManagementModel[]>> {
    return this.http.get<StaMenuManagementModel[]>(this.endPointUrl,
      {
        headers: appSettings.httpHeaders,
        observe: 'response'
      }
    );
  }

  getWithFkTitle(): Observable<HttpResponse<StaMenuManagementModel[]>> {
    return this.http.get<StaMenuManagementModel[]>(this.endPointUrl +'/GetWithFkTitle',
      {
        headers: this.headers,
        observe: 'response'
      }
    );
  }

  getGetSubMenu(id: number): Observable<HttpResponse<StaMenuManagementModel[]>> {
    return this.http.get<StaMenuManagementModel[]>(this.endPointUrl + '/GetSubMenu/' + id,
      {
        headers: this.headers,
        observe: 'response'
      }
    );
  }

  getGetId(id: number): Observable<HttpResponse<StaMenuManagementModel>> {
    return this.http.get<StaMenuManagementModel>(this.endPointUrl + '/GetId/' + id,
      {
        headers: this.headers,
        observe: 'response'
      }
    );
  }

  insert(StaMenuManagementForm: StaMenuManagementForm): Observable<HttpResponse<StaMenuManagementModel[]>> {
    return this.http.post<StaMenuManagementModel[]>(this.endPointUrl, StaMenuManagementForm,
      {
        headers: this.headers,
        observe: 'response'
      }
    );
  }

  update(StaMenuManagementForm: StaMenuManagementForm): Observable<HttpResponse<StaMenuManagementModel[]>> {
    return this.http.put<StaMenuManagementModel[]>(this.endPointUrl, StaMenuManagementForm,
      {
        headers: this.headers,
        observe: 'response'
      }
    );
  }

  delete(StaMenuManagementModel: StaMenuManagementModel): Observable<HttpResponse<StaMenuManagementModel[]>> {
    return this.http.put<StaMenuManagementModel[]>(this.endPointUrl + '/Delete/', StaMenuManagementModel,
      {
        headers: this.headers,
        observe: 'response'
      }
    );
  }

  updateSort(StaMenuManagementModel: StaMenuManagementModel): Observable<HttpResponse<StaMenuManagementModel[]>> {
    return this.http.put<StaMenuManagementModel[]>(this.endPointUrl, StaMenuManagementModel,
      {
        headers: this.headers,
        observe: 'response'
      }
    );
  }
}
