import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ContactComponent } from './components/contact/contact.component';
import { HelpComponent } from './components/help/help.component';
import { HomeComponent } from './components/home/home.component';
import { ReportComponent } from './components/report/report.component';
import { ViewDetailComponent } from './components/view-detail/view-detail.component';
import { ViewComponent } from './components/view/view.component';
import { AdminMainLayoutComponent } from './components/_layouts/admin/admin-layout/admin-main-layout.component';
import { AdminLoginLayoutComponent } from './components/_layouts/admin/admin-login-layout/admin-login-layout.component';
import { LayoutFrontComponent } from './components/_layouts/front/layout-front.component';
import { AuthenGuardService } from './services/authen-guard.service';

const routes: Routes = [
  {
    path: '',
    component: LayoutFrontComponent,
    children: [
      { path: '', component: HomeComponent },
      { path: 'home', component: HomeComponent },
      {
        path: 'view/:mainId/:subId',
        component: ViewComponent,
      },
      {
        path: 'view-detail/:mainId/:subId/:id',
        component: ViewDetailComponent,
      },
      { path: 'contact', component: ContactComponent },
      { path: 'report', component: ReportComponent },
      { path: 'faq', component: HelpComponent },
    ]
  },
  {
    path: 'admin',
    component: AdminLoginLayoutComponent,
    loadChildren: './components/admin/pages/pages.module#PagesModule'
  },
  {
    path: 'admin',
    component: AdminMainLayoutComponent,
    canActivate: [AuthenGuardService], 
    loadChildren: './components/admin/admin.module#AdminModule',
  },
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [ // กำนหด providers
    AuthenGuardService // เนื่งอจากเราจะมีการใช้งาน AuthService 
  ]
})
export class AppRoutingModule { }
