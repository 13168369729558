import { Component, NgZone, OnInit } from '@angular/core';
import {  Router } from '@angular/router';
import { AuthenModel, RoleMenuModel } from 'src/app/models/AuthenModel';
import { AuthenService } from '../../../../services/authen.service';
import { SwalAlertSuccess } from '../../../../utils/swalAlert';

@Component({
  selector: 'app-admin-header-layout',
  templateUrl: './admin-header-layout.component.html',
  styleUrls: ['./admin-header-layout.component.css']
})
export class AdminHeaderLayoutComponent implements OnInit {
  authenModel: AuthenModel;
  roleMenuModel: RoleMenuModel;
  constructor(private authenService: AuthenService,
    private router: Router,
    private ngZone: NgZone) { }

  ngOnInit(): void {
    this.authenModel = JSON.parse(localStorage.getItem('currentUser')).authenModel;
  }

  get userName(){
    if(this.authenModel){
      let userName = this.authenModel.username;
      return userName || "No Data";
    }
    return "";
  }

  get fullName(){
    if(this.authenModel){
      let fullName = this.authenModel.firstName+" "+this.authenModel.lastName;
      return fullName || "No Data";
    }
    return "";
  }

  logout() {
    this.authenService.logout();
    this.ngZone.run(() => {
      this.router.navigate(['admin']).then(() => {
        SwalAlertSuccess("Logout Success");
      });
    });
  }
}
