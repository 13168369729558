import { Component, OnInit } from '@angular/core';
import { StaMenuManagementModel } from '../../../models/sta-menu-management/StaMenuManagementModel';
import { StaMenuManagementService } from '../../../services/StaMenuManagementService';
@Component({
  selector: 'app-root',
  templateUrl: './layout-front.component.html',
  styleUrls: ['./layout-front.component.css']
})
export class LayoutFrontComponent implements OnInit  {
  title = 'app';
  isExpanded = false;
  staMenuManagementModels: StaMenuManagementModel[];

  constructor(staMenuManagementService: StaMenuManagementService) {
    staMenuManagementService.getAll().subscribe(res => {
      if (res.ok) {
       this.staMenuManagementModels = res.body;
      }
    });
  }

  get MainMenu() {
    if (this.staMenuManagementModels)
      return this.staMenuManagementModels.filter(x => x.fkStMma == null);
    else
      return [];
  }

  getSubMenu(id:number) {
    return this.staMenuManagementModels.filter(x => x.fkStMma == id);
  }

  ngOnInit() {
  }

  collapse() {
    this.isExpanded = false;
  }

  toggle() {
    this.isExpanded = !this.isExpanded;
  }
}
