import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpResponse, HttpClient, HttpHeaders } from '@angular/common/http';
import { FrontMenuManagementModel } from '../models/front-menu-management/FrontMenuManagementModel';
import { appSettings } from '../app.setting';
import { FrontMenuManagementForm } from '../models/front-menu-management/FrontMenuManagementForm';

@Injectable({
  providedIn: 'root'
})

export class FrontMenuManagementService {
  endPointUrl: string;
  headers: HttpHeaders;
  headersUpload: HttpHeaders;
  constructor(private http: HttpClient, @Inject('BASE_URL') private url: string) {
    this.endPointUrl = this.url + appSettings.endPoint + 'FrontMenuManagement';
    if (localStorage.getItem('currentUser')) {
      this.headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('currentUser')).loginToken
      });
      this.headersUpload = new HttpHeaders({
        'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('currentUser')).loginToken
      });
    }
  }
  getAllFrontMenuManagement(): Observable<HttpResponse<FrontMenuManagementModel[]>> {
    return this.http.get<FrontMenuManagementModel[]>(this.endPointUrl,
      {
        headers: this.headers,
        observe: 'response'
      }
    );
  }

  getWithCkTitle(): Observable<HttpResponse<FrontMenuManagementModel[]>> {
    return this.http.get<FrontMenuManagementModel[]>(this.endPointUrl +'/GetWithCkTitle',
      {
        headers: this.headers,
        observe: 'response'
      }
    );
  }

  getFrontMenuManagementForFrontEndCK(id: number): Observable<HttpResponse<FrontMenuManagementModel[]>> {
    return this.http.get<FrontMenuManagementModel[]>(this.endPointUrl + '/GetFoMMaByStMMa/' + id,
      {
        headers: appSettings.httpHeaders,
        observe: 'response'
      }
    );
  }

  getFrontMenuManagementCK(id: number): Observable<HttpResponse<FrontMenuManagementModel[]>> {
    return this.http.get<FrontMenuManagementModel[]>(this.endPointUrl+ '/' + id,
      {
        headers: this.headers,
        observe: 'response'
      }
    );
  }

  getFrontMenuManagementModel(id: number): Observable<HttpResponse<FrontMenuManagementModel>> {
    return this.http.get<FrontMenuManagementModel>(this.endPointUrl + '/' + id,
      {
        headers: this.headers,
        observe: 'response'
      }
    );
  }

  insertFrontMenuManagement(frontMenuManagementForm: FrontMenuManagementForm): Observable<HttpResponse<FrontMenuManagementModel[]>> {
    return this.http.post<FrontMenuManagementModel[]>(this.endPointUrl, frontMenuManagementForm,
      {
        headers: this.headers,
        observe: 'response'
      }
    );
  }

  updateFrontMenuManagement(frontMenuManagementForm: FrontMenuManagementForm): Observable<HttpResponse<FrontMenuManagementModel[]>> {
    return this.http.put<FrontMenuManagementModel[]>(this.endPointUrl, frontMenuManagementForm,
      {
        headers: this.headers,
        observe: 'response'
      }
    );
  }

  deleteFrontMenuManagement(FrontMenuManagementModel: FrontMenuManagementModel): Observable<HttpResponse<FrontMenuManagementModel[]>> {
    return this.http.put<FrontMenuManagementModel[]>(this.endPointUrl + '/Delete/', FrontMenuManagementModel,
      {
        headers: this.headers,
        observe: 'response'
      }
    );
  }

  updateSortFrontMenuManagement(frontMenuManagementModel: FrontMenuManagementModel): Observable<HttpResponse<FrontMenuManagementModel[]>> {
    return this.http.put<FrontMenuManagementModel[]>(this.endPointUrl, frontMenuManagementModel,
      {
        headers: this.headers,
        observe: 'response'
      }
    );
  }

  uploadImage(file: any): Observable<HttpResponse<FrontMenuManagementModel>> {
    return this.http.post<FrontMenuManagementModel>(this.endPointUrl +'/UploadedFile', file,
      {
        headers: this.headersUpload,
        observe: 'response'
      }
    );
  }
}
