import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-admin-main-layout',
    templateUrl: './admin-main-layout.component.html',
    styleUrls: ['./admin-main-layout.component.css']
})
/** admin-layout component*/
export class AdminMainLayoutComponent implements OnInit {
  urls: string[] = [
    "./assets/theme/plugins/global/plugins.bundle.js?v=7.0.6",
    "./assets/theme/plugins/custom/prismjs/prismjs.bundle.js?v=7.0.6",
    "./assets/theme/js/scripts.bundle.js?v=7.0.6",
    "./assets/theme/js/pages/widgets.js?v=7.0.6",
    "./assets/theme/js/pages/custom/login/login-general.js?v=7.0.6",
    "./assets/vendor/jquery/jquery.min.js",
    "./assets/vendor/jquery.easing/jquery.easing.min.js",
    "./assets/vendor/php-email-form/validate.js",
    "./assets/vendor/owl.carousel/owl.carousel.min.js",
    "./assets/vendor/waypoints/jquery.waypoints.min.js",
    "./assets/vendor/counterup/counterup.min.js",
    "./assets/vendor/isotope-layout/isotope.pkgd.min.js",
    "./assets/vendor/venobox/venobox.min.js",
    "./assets/vendor/aos/aos.js",
    "./assets/js/main.js",
  ]
  constructor() {
    this.loadScript();
  }
  ngOnInit(): void {
  }
  public loadScript() {
    console.log("preparing to load...");
    let script = 'append-';
    let cnt = 0;
    this.urls.forEach(function (value) {
      let id = script + cnt;
      let node = document.createElement("script");
      node.src = value;
      node.type = "text/javascript";
      node.async = true;
      node.charset = "utf-8";
      document.getElementsByTagName("head")[0].appendChild(node);
      /*if (!(document.getElementById(node.id))) {
        document.getElementsByTagName("head")[0].appendChild(node);
      }*/
    }); 
  }
}
