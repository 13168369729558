import { Component, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { FrontMenuManagementModel } from '../../models/front-menu-management/FrontMenuManagementModel';
import { StaMenuManagementModel } from '../../models/sta-menu-management/StaMenuManagementModel';
import { FrontMenuManagementService } from '../../services/FrontMenuManagementService';
import { StaMenuManagementService } from '../../services/StaMenuManagementService';
declare var tableau: any;

@Component({
    selector: 'app-view',
    templateUrl: './view.component.html',
    styleUrls: ['./view.component.css']
})
/** view component*/
export class ViewComponent implements OnInit, OnDestroy {
  subId: number;
  mainId: number;
  selectedNav: number;
  errMsg: string;
  currentUrl: string;
  subscription: any;
  viz1: any;
  viz2: any;
  viz3: any;
  viz4: any;
  viz5: any;
  staMenuManagementModel: StaMenuManagementModel;
  parentStaMenuManagementModel: StaMenuManagementModel;
  FrontMenuManagementModels: FrontMenuManagementModel[];
  urlDetail = "/view-detail/";
    /** view ctor */
  constructor(
    private frontMenuManagementService: FrontMenuManagementService,
    private staMenuManagementService: StaMenuManagementService,
    private route: ActivatedRoute,
    private router: Router)
  {
    this.subscription = router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        if (val.url != this.currentUrl && val.url.indexOf('view') != -1) {
          this.currentUrl = val.url;
          this.ngOnInit();
        }
      }
    });
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
  ngOnInit(): void {
    this.mainId = parseInt(this.route.snapshot.paramMap.get('mainId'));
    this.subId = parseInt(this.route.snapshot.paramMap.get('subId'));
    this.staMenuManagementService.getGetId(this.subId).subscribe(res => {
      if (res.ok) {
        this.staMenuManagementModel = res.body;
      }
    });
    this.staMenuManagementService.getGetId(this.mainId).subscribe(res => {
      if (res.ok) {
        this.parentStaMenuManagementModel = res.body;
      }
    });
    this.frontMenuManagementService.getFrontMenuManagementForFrontEndCK(this.subId).subscribe(res => {
      this.FrontMenuManagementModels = [];
      this.clearTableau();
      this.FrontMenuManagementModels = res.body;
      if (this.FrontMenuManagementModels) {
        this.selectedNav = this.FrontMenuManagementModels[0].pkFoMma;
        //this.getTablue(this.selectedNav);
      }
    },
      err => { });
  }

  get ParentTitle() {
    if (this.parentStaMenuManagementModel) {
      return this.parentStaMenuManagementModel.title;
    } else return '';
  }
  get Title() {
    if (this.staMenuManagementModel) {
      return '('+this.staMenuManagementModel.title+')';
    } else return '';
  }
  get DetailOfMenu(): string {
    if (this.staMenuManagementModel) {
      return this.staMenuManagementModel.description;
    } else return '';
  }

  viewDetail(id: number) {
    this.router.navigateByUrl(this.urlDetail
      + this.mainId
      + '/' + this.subId
      + '/' +id);
  }

  getTablue(id: number) {
    this.selectedNav = id;
    this.errMsg = "";
    try {
      this.clearTableau();
      var placeholderDiv1 = document.getElementById('vizDisplay1');
      var placeholderDiv2 = document.getElementById('vizDisplay2');
      var placeholderDiv3 = document.getElementById('vizDisplay3');
      var placeholderDiv4 = document.getElementById('vizDisplay4');
      var placeholderDiv5 = document.getElementById('vizDisplay5');
      let getModel = this.FrontMenuManagementModels.find(x => x.pkFoMma == id);
      if (getModel) {
        if (getModel.tableauUrl1 && getModel.tableauUrl1 != '') {
          var obj = this.setConfigTableau(getModel.tableauUrl1);
          this.viz1 = new tableau.Viz(placeholderDiv1, obj.url, obj.options);
        }
        if (getModel.tableauUrl2 && getModel.tableauUrl2 != '') {
          var obj = this.setConfigTableau(getModel.tableauUrl2);
          this.viz2 = new tableau.Viz(placeholderDiv2, obj.url, obj.options);
        }
        if (getModel.tableauUrl3 && getModel.tableauUrl3 != '') {
          var obj = this.setConfigTableau(getModel.tableauUrl3);
          this.viz3 = new tableau.Viz(placeholderDiv3, obj.url, obj.options);
        }
        if (getModel.tableauUrl4 && getModel.tableauUrl4 != '') {
          var obj = this.setConfigTableau(getModel.tableauUrl4);
          this.viz4 = new tableau.Viz(placeholderDiv4, obj.url, obj.options);
        }
        if (getModel.tableauUrl5 && getModel.tableauUrl5 != '') {
          var obj = this.setConfigTableau(getModel.tableauUrl5);
          this.viz5 = new tableau.Viz(placeholderDiv5, obj.url, obj.options);
        }
      }
    } catch {
      this.errMsg = "No Data";
    }
  }

  clearTableau() {
    this.errMsg = "";
    if (this.viz1) this.viz1.dispose();
    if (this.viz2) this.viz2.dispose();
    if (this.viz3) this.viz3.dispose();
    if (this.viz4) this.viz4.dispose();
    if (this.viz5) this.viz5.dispose();
  }


  setConfigTableau(_url: string): any {
    var url = _url;
    var options = {
      hideTabs: true,
      width: "100%",
      height: "650px",
      onFirstInteractive: function () {
        // The viz is now ready and can be safely used.
        console.log("Run this code when the viz has finished     loading.");
      }
    };
    return {
      url: url,
      options: options
    }
  }
}
