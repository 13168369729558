import { Component, OnInit } from '@angular/core';
import { groupBy } from 'rxjs/operators';
import { FrontReportManagementModel } from '../../models/front-report-management/FrontReportManagementModel';
import { FrontReportManagementService } from '../../services/FrontReportManagementService';

@Component({
    selector: 'app-report',
    templateUrl: './report.component.html',
    styleUrls: ['./report.component.css']
})
/** report component*/
export class ReportComponent implements OnInit {
  FrontReportManagementModels: FrontReportManagementModel[];
  FrontReportManagementViewModels: FrontReportManagementModel[];
  YearAll = [];
  groupByYear = {};
  selectedNav:number;
  /** report ctor */
  constructor(frontReportManagementService: FrontReportManagementService) {
    frontReportManagementService.getAll().subscribe(resModels => {
      if (resModels.body) {
        this.FrontReportManagementModels = resModels.body;
        frontReportManagementService.getYearAll().subscribe(resYear => {
          if (resYear.ok) {
            this.YearAll = resYear.body;
            if (resYear.body && resYear.body.length > 0) {
              this.selectedNav = resYear.body[0];
              this.FrontReportManagementViewModels = this.FrontReportManagementModels.filter(x => x.year === resYear.body[0]);
            }
          }
        });
      }
    });
  }
  ngOnInit(): void {
  }

  getYear(year: number) {
    this.selectedNav = year;
    this.FrontReportManagementViewModels = this.FrontReportManagementModels.filter(x => x.year === year);
  }

  get GetFrontReportManagementViewModels() {
    if (this.FrontReportManagementViewModels && this.FrontReportManagementViewModels.length > 0)
      return this.FrontReportManagementViewModels;
    else {
      return [];
    }
  }
}
