import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppComponent } from './app.component';
import { CommonModule } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { LayoutFrontComponent } from './components/_layouts/front/layout-front.component';
import { AdminMainLayoutComponent } from './components/_layouts/admin/admin-layout/admin-main-layout.component';
import { AdminAsideMenuLayoutComponent } from './components/_layouts/admin/admin-aside-menu-layout/admin-aside-menu-layout.component';
import { AdminHeaderLayoutComponent } from './components/_layouts/admin/admin-header-layout/admin-header-layout.component';
import { AdminHeaderMobileLayoutComponent } from './components/_layouts/admin/admin-header-mobile-layout/admin-header-mobile-layout.component';
import { AdminLoginLayoutComponent } from './components/_layouts/admin/admin-login-layout/admin-login-layout.component';
import { FooterComponent } from './components/footer/footer.component';
import { HomeComponent } from './components/home/home.component';
import { ContactComponent } from './components/contact/contact.component';
import { AuthenService } from './services/authen.service';
import { ViewComponent } from './components/view/view.component';
import { ViewDetailComponent } from './components/view-detail/view-detail.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FaqComponent } from './components/faq/faq.component';
import { ReportComponent } from './components/report/report.component';
import { HelpComponent } from './components/help/help.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { AuthenInterceptor } from './services/authen-interceptor.service';

@NgModule({
  declarations: [
    AppComponent,
    LayoutFrontComponent,
    AdminMainLayoutComponent,
    AdminAsideMenuLayoutComponent,
    AdminHeaderLayoutComponent,
    AdminHeaderMobileLayoutComponent,
    AdminLoginLayoutComponent,
    FooterComponent,
    HomeComponent,
    ContactComponent,
    ViewComponent,
    ViewDetailComponent,
    FaqComponent,
    ReportComponent,
    HelpComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    AppRoutingModule,
    CommonModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    CarouselModule,
    BrowserAnimationsModule,
    AngularEditorModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [AuthenService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthenInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
