import Swal from 'sweetalert2';

export function SwalAlertWaiting () {
  Swal.fire({
    title: "Waiting!",
    timerProgressBar: true,
    showConfirmButton: false,
    allowOutsideClick:false,
  })
}

export function SwalAlertSuccess (text: string) {
  Swal.fire({
    title: "Success!",
    text: text,
    confirmButtonText: "Close",
    icon: "success",
    allowOutsideClick:false,
  })
}

export function SwalAlertWarning(text: string) {
  Swal.fire({
    title: "Warning!",
    text: text,
    confirmButtonText: "Close",
    icon: "warning",
    allowOutsideClick:false,
  })
}

export function SwalAlertErrors (text: string) {
  Swal.fire({
    title: "Errors!",
    text: text,
    confirmButtonText: "Close",
    icon: "error",
    allowOutsideClick:false,
  })
}

export const SwalAlertMessage: any = {
  savedMessage: 'Your task has been saved.',
  cancelMessage: 'Your task has been canceled.',
  deletedMessage: 'Your task has been deleted.',
  errorMessage: 'Errors',
  permissionEditMessage: 'Permission not allow edit action',
};
