import { Component } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { FrontHelpAttachManagementModel } from '../../models/front-help-attach-management/FrontHelpAttachManagementModel';
import { FrontHelpManagementModel } from '../../models/front-help-management/FrontHelpManagementModel';
import { FrontHelpAttachManagementService } from '../../services/FrontHelpAttachManagementService';
import { FrontHelpManagementService } from '../../services/FrontHelpManagementService';

@Component({
    selector: 'app-help',
    templateUrl: './help.component.html',
    styleUrls: ['./help.component.css']
})
/** help component*/
export class HelpComponent {
  FrontHelpManagementModel: FrontHelpManagementModel;
  FrontHelpAttachManagementModels: FrontHelpAttachManagementModel[];
  /** help ctor */
  constructor(
    private sanitizer: DomSanitizer,
    frontHelpManagementService: FrontHelpManagementService,
    frontHelpAttachManagementService: FrontHelpAttachManagementService
  )
  {
    frontHelpManagementService.getModel(1).subscribe(res => {
      if (res.ok) {
        this.FrontHelpManagementModel = res.body;
      }
    });
    frontHelpAttachManagementService.getAll().subscribe(res => {
      if (res.ok) {
        this.FrontHelpAttachManagementModels = res.body;
      }
    });
  }

  get getTextHtml() {
    if (this.FrontHelpManagementModel && this.FrontHelpManagementModel.textHtml)
      return this.sanitizer.bypassSecurityTrustHtml(this.FrontHelpManagementModel.textHtml);
    else return "";
  }
}
