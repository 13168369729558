import { Injectable } from '@angular/core';
import {
  CanActivate, Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanActivateChild
} from '@angular/router';
import { AuthenService } from './authen.service';
@Injectable()
export class AuthenGuardService {
  // inject AuthService และ Router 
  constructor(
    private authenService: AuthenService,
    private router: Router) { }

  // กำนหนด guard ในส่วนของการใช้งานกับ  canActivate
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    //console.log('canActivate run');
    let url: string = state.url; // เก็บ url ที่พยายามจะเข้าใช้งาน
    console.log('canActivate url', url);
    // จะผ่านเข้าใช้งานได้เมื่อ คืนค่าเป็น true โดยเข้าไปเช็คค่าจากคำสั่ง checkLogin()
    return this.checkLogin(url); // คืนค่าการตรวจสอบสถานะการล็อกอิน
  }

  // กำนหนด guard ในส่วนของการใช้งานกับ  canActivateChild ส่วนนี้จะใช้กับ path ของ route ย่อย
  // ถ้าเข้าผ่าน route path ย่อย guard จะเข้ามาเช็คในส่วนนี้ก่อน กลับไปเช็คในส่วนของ canActivate()
  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    //console.log('canActivateChild run');
    // จะเข้าใช้งานได้เมื่อ คืนค่าเป็น true โดยจะใช้ค่าจากการเรียกใช้คำสั่ง canActivate()
    let url: string = state.url; // เก็บ url ที่พยายามจะเข้าใช้งาน
    console.log('canActivateChild url', url);
    return this.canActivate(route, state);
  }

  // ฟังก์ชั่นเช็คสถานะการล็อกอิน รับค่า url ที่ผู้ใช้พยายามจะเข้าใช้งาน
  checkLogin(url: string): boolean {
    // ถ้าตรวจสอบค่าสถานะการล็อกอินแล้วเป็น true ก็ให้คืนค่า true กลับอกไป
    if (this.authenService.isLoggedIn) { return true; }
    if (localStorage.getItem('currentUser')) {
      if (this.authenService.login()) {
        return true;
      }
    }

    // แต่ถ้ายังไม่ได้ล็อกอิน ให้เก็บ url ที่พยายามจะเข้าใช้งาน สำหรับไว้ลิ้งค์เปลี่ยนหน้า
    this.authenService.redirectUrl = url; // redirectUrl เป็นตัวแปรที่อยู่ใน authService

    // ลิ้งค์ไปยังหน้าล็อกอิน เพื่อล็อกอินเข้าใช้งานก่อน
    this.router.navigate(['admin','login']);
    return false; // คืนค่า false กรณียังไม่ได้ล็อกอิน
  }   
}
