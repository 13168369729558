import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpResponse, HttpClient, HttpHeaders } from '@angular/common/http';
import { FrontReportManagementModel } from '../models/front-report-management/FrontReportManagementModel';
import { appSettings } from '../app.setting';
import { FrontReportManagementForm } from '../models/front-report-management/FrontReportManagementForm';

@Injectable({
  providedIn: 'root'
})

export class FrontReportManagementService {
  endPointUrl: string;
  headers: HttpHeaders;
  headersUpload: HttpHeaders;
  constructor(private http: HttpClient, @Inject('BASE_URL') private url: string) {
    this.endPointUrl = this.url + appSettings.endPoint + 'FrontReportManagement';
    if (localStorage.getItem('currentUser')) {
      this.headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('currentUser')).loginToken
      });
      this.headersUpload = new HttpHeaders({
        'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('currentUser')).loginToken
      });
    }
  }

  getAll(): Observable<HttpResponse<FrontReportManagementModel[]>> {
    return this.http.get<FrontReportManagementModel[]>(this.endPointUrl,
      {
        headers: appSettings.httpHeaders,
        observe: 'response'
      }
    );
  }

  getYearAll(): Observable<HttpResponse<number[]>> {
    return this.http.get<number[]>(this.endPointUrl +'/GetYearAll',
      {
        headers: appSettings.httpHeaders,
        observe: 'response'
      }
    );
  }

  getModel(id: number): Observable<HttpResponse<FrontReportManagementModel>> {
    return this.http.get<FrontReportManagementModel>(this.endPointUrl + '/' + id,
      {
        headers: this.headers,
        observe: 'response'
      }
    );
  }

  insert(FrontReportManagementForm: FrontReportManagementForm): Observable<HttpResponse<FrontReportManagementModel[]>> {
    return this.http.post<FrontReportManagementModel[]>(this.endPointUrl, FrontReportManagementForm,
      {
        headers: this.headers,
        observe: 'response'
      }
    );
  }

  update(FrontReportManagementForm: FrontReportManagementForm): Observable<HttpResponse<FrontReportManagementModel[]>> {
    return this.http.put<FrontReportManagementModel[]>(this.endPointUrl, FrontReportManagementForm,
      {
        headers: this.headers,
        observe: 'response'
      }
    );
  }

  delete(FrontReportManagementModel: FrontReportManagementModel): Observable<HttpResponse<FrontReportManagementModel[]>> {
    return this.http.put<FrontReportManagementModel[]>(this.endPointUrl + '/Delete/', FrontReportManagementModel,
      {
        headers: this.headers,
        observe: 'response'
      }
    );
  }

  updateSort(FrontReportManagementModel: FrontReportManagementModel): Observable<HttpResponse<FrontReportManagementModel[]>> {
    return this.http.put<FrontReportManagementModel[]>(this.endPointUrl, FrontReportManagementModel,
      {
        headers: this.headers,
        observe: 'response'
      }
    );
  }

  uploadFile(file: any): Observable<HttpResponse<FrontReportManagementModel>> {
    return this.http.post<FrontReportManagementModel>(this.endPointUrl + '/UploadedFile', file,
      {
        headers: this.headersUpload,
        observe: 'response'
      }
    );
  }
  uploadImageCover(file: any): Observable<HttpResponse<FrontReportManagementModel>> {
    return this.http.post<FrontReportManagementModel>(this.endPointUrl + '/UploadedImageCover', file,
      {
        headers: this.headersUpload,
        observe: 'response'
      }
    );
  }
}
