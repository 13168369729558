import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpResponse, HttpClient, HttpHeaders } from '@angular/common/http';
import { FrontHelpAttachManagementModel } from '../models/front-help-attach-management/FrontHelpAttachManagementModel';
import { appSettings } from '../app.setting';
import { FrontHelpAttachManagementForm } from '../models/front-help-attach-management/FrontHelpAttachManagementForm';

@Injectable({
  providedIn: 'root'
})

export class FrontHelpAttachManagementService {
  endPointUrl: string;
  headers: HttpHeaders;
  headersUpload: HttpHeaders;
  constructor(private http: HttpClient, @Inject('BASE_URL') private url: string) {
    this.endPointUrl = this.url + appSettings.endPoint + 'FrontHelpAttachManagement';
    if (localStorage.getItem('currentUser')) {
      this.headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('currentUser')).loginToken
      });
      this.headersUpload = new HttpHeaders({
        'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('currentUser')).loginToken
      });
    }
  }

  getAll(): Observable<HttpResponse<FrontHelpAttachManagementModel[]>> {
    return this.http.get<FrontHelpAttachManagementModel[]>(this.endPointUrl,
      {
        headers: appSettings.httpHeaders,
        observe: 'response'
      }
    );
  }

  getModel(id: number): Observable<HttpResponse<FrontHelpAttachManagementModel>> {
    return this.http.get<FrontHelpAttachManagementModel>(this.endPointUrl + '/' + id,
      {
        headers: this.headers,
        observe: 'response'
      }
    );
  }

  insert(FrontHelpAttachManagementForm: FrontHelpAttachManagementForm): Observable<HttpResponse<FrontHelpAttachManagementModel[]>> {
    return this.http.post<FrontHelpAttachManagementModel[]>(this.endPointUrl, FrontHelpAttachManagementForm,
      {
        headers: this.headers,
        observe: 'response'
      }
    );
  }

  update(FrontHelpAttachManagementForm: FrontHelpAttachManagementForm): Observable<HttpResponse<FrontHelpAttachManagementModel>> {
    return this.http.put<FrontHelpAttachManagementModel>(this.endPointUrl, FrontHelpAttachManagementForm,
      {
        headers: this.headers,
        observe: 'response'
      }
    );
  }

  delete(FrontHelpAttachManagementModel: FrontHelpAttachManagementModel): Observable<HttpResponse<FrontHelpAttachManagementModel>> {
    return this.http.put<FrontHelpAttachManagementModel>(this.endPointUrl + '/Delete/', FrontHelpAttachManagementModel,
      {
        headers: this.headers,
        observe: 'response'
      }
    );
  }

  updateSort(FrontHelpAttachManagementModel: FrontHelpAttachManagementModel): Observable<HttpResponse<FrontHelpAttachManagementModel>> {
    return this.http.put<FrontHelpAttachManagementModel>(this.endPointUrl, FrontHelpAttachManagementModel,
      {
        headers: this.headers,
        observe: 'response'
      }
    );
  }

  uploadFile(file: any): Observable<HttpResponse<FrontHelpAttachManagementModel>> {
    return this.http.post<FrontHelpAttachManagementModel>(this.endPointUrl + '/UploadedFile', file,
      {
        headers: this.headersUpload,
        observe: 'response'
      }
    );
  }
}
