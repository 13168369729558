import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpResponse, HttpClient, HttpHeaders } from '@angular/common/http';
import { FrontHelpManagementModel } from '../models/front-help-management/FrontHelpManagementModel';
import { appSettings } from '../app.setting';
import { FrontHelpManagementForm } from '../models/front-help-management/FrontHelpManagementForm';

@Injectable({
  providedIn: 'root'
})

export class FrontHelpManagementService {
  endPointUrl: string;
  headers: HttpHeaders;
  constructor(private http: HttpClient, @Inject('BASE_URL') private url: string) {
    this.endPointUrl = this.url + appSettings.endPoint + 'FrontHelpManagement';
    if (localStorage.getItem('currentUser')) {
      this.headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('currentUser')).loginToken
      });
    }
  }

  getModel(id: number): Observable<HttpResponse<FrontHelpManagementModel>> {
    return this.http.get<FrontHelpManagementModel>(this.endPointUrl + '/' + id,
      {
        headers: this.headers,
        observe: 'response'
      }
    );
  }

  update(FrontHelpManagementForm: FrontHelpManagementForm): Observable<HttpResponse<FrontHelpManagementModel>> {
    return this.http.put<FrontHelpManagementModel>(this.endPointUrl, FrontHelpManagementForm,
      {
        headers: this.headers,
        observe: 'response'
      }
    );
  }
}
