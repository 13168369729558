import { Component, OnInit } from '@angular/core';
import { AuthenModel } from '../../../../models/AuthenModel';

@Component({
  selector: 'app-admin-aside-menu-layout',
  templateUrl: './admin-aside-menu-layout.component.html',
  styleUrls: ['./admin-aside-menu-layout.component.css']
})
export class AdminAsideMenuLayoutComponent implements OnInit {
  authenModel: AuthenModel;
  constructor() { }

  ngOnInit(): void {
    this.authenModel = JSON.parse(localStorage.getItem('currentUser')).authenModel;
  }

  get roleMenuModels() {
    if (this.authenModel) {
      return this.authenModel.roleMenuModels.filter(x => x.authenMenuModel.fkSyAme == null && x.isView);
    } else {
      return [];
    }
  }

  getSubMenu(id: number) {
    return this.authenModel.roleMenuModels.filter(x => x.authenMenuModel.fkSyAme == id && x.isView);
  }
}
