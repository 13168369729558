import { Component } from '@angular/core';
import { FrontFaqManagementModel } from '../../models/front-faq-management/FrontFaqManagementModel';
import { FrontFaqManagementService } from '../../services/FrontFaqManagementService';

@Component({
    selector: 'app-faq',
    templateUrl: './faq.component.html',
    styleUrls: ['./faq.component.css']
})
/** faq component*/
export class FaqComponent {
  FrontFaqManagements: FrontFaqManagementModel[];
  /** faq ctor */
  constructor(frontFaqManagementService: FrontFaqManagementService) {
    frontFaqManagementService.getAll().subscribe(res => {
      if (res.ok) {
        this.FrontFaqManagements = res.body;
      }
    });
  }
}
