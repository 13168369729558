import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { appSettings } from '../app.setting';

@Injectable()
export class AuthenService {
  public isLoggedIn: boolean = false; // กำหนดสถานะล็อกอินเริ่มต้นเป็น false
  public redirectUrl: string; // กำหนดตัวแปรสำหรับเก็บ url ที่จะลิ้งค์ไป

  constructor() { }

  login(): Observable<boolean> {
    // เมื่อล็อกอิน
    if (localStorage.getItem('currentUser')) {
      var currentUser = JSON.parse(localStorage.getItem('currentUser'));
      if (currentUser.loginBool) {
        this.isLoggedIn = true
        return of(true); // ให้คืนค่า true
      }
    } else {
      this.isLoggedIn = false
      return of(false); // ให้คืนค่า false
    }
  }

  // ฟังก์ชั่นจำลองการล็อกเอาท์
  logout(): void {
    // ให้ค่าสถานะล็อกอินเป็น false
    localStorage.clear();
    this.isLoggedIn = false;
  }  
}
